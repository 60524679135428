import React from 'react';
import "../css/Preloader.css";
const Preloader = ({color}) => {

    return (
        <div className="preloader-container">
            { <div className={"lds-ring " + color} ></div> }
        </div>
    );
};

export default Preloader;
import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

const PropertyPlan = (props) => {


    if(props.svgValue == null) return null;

    return (
        <Link to={props.link}>
            <g className={props.isFav ? "" : "d-none"}  transform={"translate("+(props.svgValue.x - 5)+","+(props.svgValue.y - 35)+") scale(0.6)"}>
                <path className={"fav"} fill={"#FA4D53"} stroke={"#FFFFFF"} strokeWidth={1} d="M10.5,18.1c-0.3,0-0.5,0-0.8-0.1C6.3,16.8,1,12.7,1,6.7c0-3.1,2.5-5.6,5.6-5.6c1.5,0,2.9,0.6,3.9,1.6
	c1-1,2.4-1.6,3.9-1.6c3.1,0,5.6,2.5,5.6,5.6c0,6.1-5.3,10.1-8.7,11.3C11,18.1,10.7,18.1,10.5,18.1z"/>
            </g>
            <g className={props.className}>
                <polygon className="cls-2" points={props.svgValue.path}/>
                <text className="title" x={props.svgValue.x} y={props.svgValue.y}>{props.title}</text>
                <text className="subtitle" x={props.svgValue.x} y={props.svgValue.y}>{props.roomType}</text>
                <circle className="cls-3" cx={props.svgValue.x} cy={props.svgValue.y} r="26"/>
            </g>
        </Link>
    );
};

export default PropertyPlan;
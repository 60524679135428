import React, {useContext, useEffect, useState} from 'react';
import '../css/FloorView.css';
import {Link, withRouter, useParams} from "react-router-dom";

import FloorPlan from "./svg/FloorPlan.js";
import {apiConst} from "../constant/apiConst";
import AppDataContext from "./context/AppDataContext";
import {floorConst} from "../constant/floorConst";
import FavContext from "./context/FavContext";
import {Image} from "react-img-placeholder";
import {useTranslation} from "react-i18next";



function FloorView(props) {
  const {appData, setAppData} = useContext(AppDataContext);
  const params = useParams();
  const [floorCode,setFloorCode] = useState(null);
  const [floorProps,setFloorProps] = useState([]);
  const [buildingCode,setBuildingCode] = useState();
    const { t } = useTranslation();

  useEffect(() => {
      if(appData.loaded)
      {
          appData.buildings.forEach(function(building,i){
              if(building.id === parseInt(params.building_id)){
                  setBuildingCode(building.code);
                  let code = (building.class_name + "f"+params.floor_id);

                  apiConst.findPropsOnFloor(params.building_id,params.floor_id).then(result => {
                      setFloorCode(code);
                      setFloorProps(result);
                  })
              }
          })
      }

  },[appData])


  return (

    <section className={appData.isFilterModeActive ? "primary floor-plan filter-active" : "primary floor-plan"}>


        <button className="close-button colored" onClick={() => props.history.goBack()}>
          <div></div>
          <div></div>
        </button>

        <div className="color-info">
            <span className="available text-uppercase">{t("available")}</span>
            <span className="reserved text-uppercase">{t("reserved")}</span>
            <span className="sold text-uppercase">{t("sold")}</span>
            <span className="blocked text-uppercase">{t("blocked")}</span>
        </div>

    <div className="main-title">
        {buildingCode && <div className="text">{buildingCode}</div>}
      <div className="number text-uppercase"> {params.floor_id>0 ? t("floor_view.floor") + " " + params.floor_id : t("floor_view.floor_g")}</div>
    </div>

      <div className="inner ">

        <div className="paths ">
            {floorCode && <FloorPlan properties={floorProps} floorCode={floorCode} floorId={params.floor_id} appData={appData} planSvg={floorConst[floorCode]} />}
        </div>

      </div>

    </section>

  );
}

export default withRouter(FloorView);
import React from 'react';
import SubMenuItem from "./SubMenuItem";
import '../css/SubMenu.css';
import '../css/GallerySubMenu.css';
import {appConst} from "../constant/appConst";
import {Link} from "react-router-dom";

const GallerySubMenu = (props) => {


    return (
        <div className="gallery-menu side-menu">
            <div className="inner">
                <button className={"close-button"} onClick={props.onClickClose}>
                    <div></div>
                    <div></div>
                </button>
                <div className="submenu">
                    {appConst.menu.gallery.map((sub,i) => (
                        <SubMenuItem title={sub.title} isExternal={sub.isExternal} link={sub.link} isLast={sub.length === (i+1)} key={sub.id} />
                    ))}
                </div>
            </div>


        </div>
    );
};

export default GallerySubMenu;
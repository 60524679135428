import React from 'react';
import "../css/LoadingOverlay.css";
import Preloader from "./Preloader";
const LoadingOverlay = ({loaded}) => {
    return (
        <div id="loading-overlay" className={loaded ? "loading-overlay hide-loading-overlay" : "loading-overlay"}>
            <Preloader color="primary" />
        </div>
    );
};

export default LoadingOverlay;
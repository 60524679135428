import React, {useState} from 'react';
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import {Link, useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";


function UserForgotPassword() {

  const [fields, setFields] = useState({code:""});
  const [errors, setErrors] = useState({code:""});
  const [isLoading,setIsLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  function onClickSend(){
    if(handleValidation())
    {
      setIsLoading(true);

      apiConst.userForgotPassword(fields["email"]).then(result => {
        setIsLoading(false);

        if(result.response)
        {
            history.push("/formResult/forgotPassword");
        }
        else {
          errors["email"] = t("error_no_such_user") ;
          setErrors(errors);

        }


      })
    }

  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["email"])
    {
      formIsValid = false;
      errors["email"] = t("error_email_empty");
    }
    else
    {
      if(typeof fields["email"] !== "undefined"){
        let lastAtPos = fields["email"].lastIndexOf('@');
        let lastDotPos = fields["email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }

      }

    }

    setErrors(errors)
    return formIsValid;
  }

  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }


  return (
    <section className="sign-section">
    <div className="sign-wrap">

        <div className="inner">
          <h2 className={"text-uppercase"}>{t("get_password_title")}</h2>
          <div className="form-element">
            <input type="text" className={errors["email"] ? "form-control is-invalid" : "form-control"}  id="email" placeholder={t("email")} onChange={(e) => handleChange("email",e)} />
            {
              errors["email"] && <div className="invalid-feedback">
                {errors["email"]}
              </div>
            }
          </div>


          <div className="mt-4">
            <ButtonWithLoading className={"default-button login"} onClick={onClickSend} label={t("send")} isLoading={isLoading} />
          </div>

          <div className="mt-4 ">
            <div className="seperator-text">
              {t("or")}
            </div>
          </div>
          <div className="mt-4">
            <Link className={"default-button lined login"} to="/salesLogin">{t("login")}</Link>
          </div>

        </div>




    </div>
    </section>
  );
}

export default UserForgotPassword;
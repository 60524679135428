export const propertyConst = {
    slider : {




    },
    thumb : {


    },
    floorplan_slider : {


    },

    vr360 : {

    }
}
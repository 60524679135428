import React from 'react';
import {Link} from "react-router-dom";

const SubMenuItem = ({title}) => {

    return (
        <div className={"submenu-title"}>
            {title}
        </div>


    );
};

export default SubMenuItem;
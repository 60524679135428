import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import {Link,useParams,useHistory} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";
import {useTranslation} from "react-i18next";



function ClientVerificationDirectly() {

  const {id,code} = useParams();
  const [fields, setFields] = useState({code:""});
  const [errors, setErrors] = useState({code:""});
  const [isLoading,setIsLoading] = useState(false);
  const { alert, setAlert } = useContext(AlertContext);
  const { client, setClient } = useContext(ClientContext);
  const { user, setUser } = useContext(UserContext);
  const { favs, setFavs } = useContext(FavContext);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if(client)
    {

      setFavs(client.favs);
      sessionStorage.setItem("favs",JSON.stringify(client.favs));

      apiConst.presentationCreate(client.user.id,client.id).then(result => {
        if(result.response)
        {
          sessionStorage.setItem("client",JSON.stringify(client));
          sessionStorage.setItem("presentation_id",result.presentation.id);
          setAlert(t("hello") + " " + client.first_name + " " + client.last_name);
          history.push("/");

        }
      })
    }
  },[client]);

  useEffect(() => {
    if(user)
    {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("presentation_id");
      sessionStorage.removeItem("client");
      sessionStorage.removeItem("favs");
      sessionStorage.setItem("user",JSON.stringify(user));
    }

  },[user]);

  useEffect(()=> {
    apiConst.clientVerify(code,id).then(result => {

      if(result.response)
      {
        setUser(result.client.user);
        setClient(result.client);

      }
      else
      {
        history.push("/formResult/invalidCode")
      }

    })
  },[])

  return (
    <div />
  );
}

export default ClientVerificationDirectly;
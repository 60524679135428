import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {appConst} from "../constant/appConst";
import ReactPlayer from "react-player";
import Slider from "./Slider";
import "../css/Gallery.css";
import Brochure from "./Brochure";

const Gallery = () => {
    const { id } = useParams();

    return (
        <section className="primary gallery">
            {(id === "exterior") && <Slider gallery={appConst.gallery.exterior}  />}
            {(id === "interior") && <Slider gallery={appConst.gallery.interior}  />}
            {(id === "social") && <Slider gallery={appConst.gallery.social}  />}
            {(id === "brochure") && <Brochure  />}


        </section>
    );

};

export default Gallery;
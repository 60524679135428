import React, {useContext, useEffect, useState} from 'react';
import "../css/FavoriteList.css";
import {appUtil} from "../constant/appUtil";
import {apiConst} from "../constant/apiConst";
import AlertContext from "./context/AlertContext";
import LoadingOverlay from "./LoadingOverlay";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";
import {Link} from "react-router-dom";
import {appSettings} from "../constant/appConst";
import {propertyConst} from "../constant/propertyConst";
import FavoriteListItem from "./FavoriteListItem";
import FavCompareContext from "./context/FavCompareContext";
import {useTranslation} from "react-i18next";


const FavoriteList = () => {
    const {favCompareList,setFavCompareList} = useContext(FavCompareContext);
    const {favs,setFavs} = useContext(FavContext);
    const {client,setClient} = useContext(ClientContext);
    const {alert,setAlert} = useContext(AlertContext);
    const [shareActive,setShareActive] = useState(false);
    const { t } = useTranslation();
    const [shareButtonText,setShareButtonText] = useState(t("favorites.share_selected_properties"));

    useEffect(()=> {
        setShareActive(favCompareList.length)
    },[favCompareList])
    function onShareClick() {

        if(favCompareList.length) {
            setShareActive(false);
            setShareButtonText(t("favorites.please_wait_when_sharing"))
            let properties = []

            favCompareList.forEach(item => {
                properties.push(item.property_id);
            })

            apiConst.propertyShare(client.id,properties).then(result => {
                if(result.response)
                {
                    setAlert(t("favorites.share_success"));
                }
                else {
                    setAlert(t("error_server"));
                }
                setShareActive(true);
                setShareButtonText(t("favorites.share_selected_properties"))
            })
        }


    }

    return (
        <div>
            <LoadingOverlay loaded={true}/>
            { favs.length === 0 &&
            <div className="no-fav-container">
                <div className="no-fav"></div>
                <span>{t("favorites.no_fav")}</span>
            </div>
            }
            { favs.length > 0 &&
            <div>
                <div className="fav-list">

                    {favs && favs.map(fav => (
                        <FavoriteListItem fav={fav} key={fav.id} />
                    ))}

                    <button className={shareActive ? "share-button default-button" : "share-button default-button btn-disabled"} disabled={!shareActive} onClick={onShareClick} ><span className={"icon-share"}></span><span className={"share-button-txt"}>{shareButtonText}</span></button>
                </div>

            </div>}
        </div>

    );
};

export default FavoriteList;
import React, {useState} from 'react';
import Preloader from "./Preloader";
import "../css/ButtonWithLoading.css";
const ButtonWithLoading = ({className,label,onClick,isLoading}) => {
    return (
        <div className={ isLoading ? "button-container is-loading" : "button-container"}>
            <div className="button-preloader"><Preloader color={className.includes("lined") ? "primary" : "white"} /></div>

            <button className={className} onClick={onClick}>
                <span className="button-text">{label}</span>
            </button>
        </div>

    );
};

export default ButtonWithLoading;
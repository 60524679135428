import React, {useContext, useEffect, useState} from 'react';
import "../css/FavoritesSubMenu.css";
import FavoriteList from "./FavoriteList";
import {useTranslation} from "react-i18next";
const FavoritesSubMenu = (props) => {
    const { t } = useTranslation();

    return (
        <div className="favorites-menu side-menu">

            <div className="inner">
                <div className={"title"}>
                    <h2>{t("favorites.title")}</h2>
                </div>
                <div className="content">
                    <FavoriteList />
                </div>

            </div>

        </div>
    );
};

export default FavoritesSubMenu;
import React from 'react';
import "../css/FullScreenButton.css";
function FullScreenButton(props) {

    return (
        <div >
            <button className={"btn-fullscreen"} onClick={props.onClick}><span className="icon"></span></button>
        </div>
    );
}

export default FullScreenButton;
import React, {useState} from 'react';
import "../css/FavButton.css";


import Preloader from "./Preloader";
import {useTranslation} from "react-i18next";

const FavButton = ({isFav,isWaiting,onClick}) => {

    const { t } = useTranslation();
    return (
        <div className={isFav ? "fav-button is-fav default-button" : "fav-button default-button"} onClick={onClick}>
           <div className="icon-like" />
            <span>{t("like")}</span>
        </div>
    );
};

export default FavButton;
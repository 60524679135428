import React, {useContext, useEffect, useState} from 'react';
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import {Link, useHistory, useParams} from "react-router-dom";
import AlertContext from "./context/AlertContext";
import {useTranslation} from "react-i18next";


function UserResetPassword() {
  const [validToken,setValidToken] = useState(false);
  const [fields, setFields] = useState({password:"",repassword:""});
  const [errors, setErrors] = useState({password:"",repassword:""});
  const [isLoading,setIsLoading] = useState(false);
  const { alert, setAlert } = useContext(AlertContext);
  const history = useHistory();
  const {id,token} = useParams();
  const { t } = useTranslation();

  useEffect(()=> {
    apiConst.userCheckResetToken(id,token).then(result => {


      if(result.response)
      {
        setValidToken(true);
      }
      else {
        history.push("/formResult/invalidToken");

      }


    })
  },[])

  function onClickSend(){
    if(handleValidation())
    {

      setIsLoading(true);
      apiConst.userResetPassword(id,fields["password"]).then(result => {
        setIsLoading(false);

        if(result.response)
        {
            history.push("/formResult/passwordUpdate");
        }
        else {
          setAlert(t("error_server"));
        }
      })
    }

  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["password"]){
      formIsValid = false;
      errors["password"] = t("error_password_empty");
    }
    else {
      if(fields["password"].length < 3) {
        formIsValid = false;
        errors["password"] = t("error_password_min_3");
      }
    }

    if(!fields["repassword"]){
      formIsValid = false;
      errors["repassword"] = t("error_repassword_empty");
    }

    if(fields["password"] !== fields["repassword"]){
      formIsValid = false;
      errors["repassword"] = t("error_password_not_matching");
    }

    setErrors(errors)
    return formIsValid;
  }

  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }


  return (
    <section className="sign-section">
    <div className="sign-wrap">

        <div className="inner">
          <h2 className={"text-uppercase"}>{t("password_reset.reset_password")}</h2>

          <div className="form-element">
            <input type="password" className={errors["password"] ? "form-control is-invalid" : "form-control"} id="password" placeholder={t("password_reset.password")} onChange={(e) => handleChange("password",e)} />
            {
              errors["password"] && <div className="invalid-feedback">
                {errors["password"]}
              </div>
            }
          </div>

          <div className="form-element">
            <input type="password" className={errors["repassword"] ? "form-control is-invalid" : "form-control"} id="repassword" placeholder={t("password_reset.password_again")} onChange={(e) => handleChange("repassword",e)} />
            {
              errors["repassword"] && <div className="invalid-feedback">
                {errors["repassword"]}
              </div>
            }
          </div>

          <div className="mt-4">
            <ButtonWithLoading className={"default-button login"} onClick={onClickSend} label={t("save")} isLoading={isLoading} />
          </div>

          <div className="mt-4 ">
            <div className="seperator-text">
              {t("or")}
            </div>
          </div>
          <div className="mt-4">
            <Link className={"default-button lined login"} to="/login">{t("login")}</Link>
          </div>

        </div>




    </div>
    </section>
  );
}

export default UserResetPassword;
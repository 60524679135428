import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (e) => {
        const newLang = e.target.value;
        i18n.changeLanguage(newLang);
        document.querySelector(".legacy_text").innerHTML = i18n.t("legacy_text");
        document.querySelector("#toggle-label-button").innerHTML = i18n.t("hide_label");
        document.querySelector("#toggle-label-hide-text").value = i18n.t("hide_label");
        document.querySelector("#toggle-label-show-text").value = i18n.t("show_label");
        document.documentElement.lang = newLang
    };

    return (
        <select className={"form-control"} value={i18n.language} onChange={handleLanguageChange}>
            <option value="tr">TR</option>
            <option value="en">EN</option>
        </select>
    );
};

export default LanguageSwitcher;
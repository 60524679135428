import React, {useContext, useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import '../css/MasterPlanView.css';
import {Image} from "react-img-placeholder";
import {buildingConst} from "../constant/buildingConst";


function MasterPlanView(props) {

	useEffect(() => {

		window.addEventListener('resize', handleResize)
		setTimeout(handleResize,500);

	},[])

	function handleResize() {
		var svgs = [...document.querySelectorAll('.svg__link')]
		var labels = [...document.querySelectorAll('.labels__item')]
		svgs.forEach((el,i) => {

			if(labels[i]) {
				labels[i].classList.add("labels__item--visible")


				labels[i].style.left = getOffset(el).left + getOffset(el).width/2 + "px";
				labels[i].style.top = getOffset(el).top + (getOffset(el).height/2) + "px";

			}


		})
	}

	function getOffset(el) {
		const rect = el.getBoundingClientRect();
		return {
			left: rect.left ,
			top: rect.top,
			width : rect.width,
			height : rect.height
		};
	}

	window.addEventListener('viewMasterPlan',function(){
		document.querySelector(".masterplan").classList.remove("d-none")
		handleResize();
	})
	window.addEventListener('hideMasterPlan',function(){
		document.querySelector(".masterplan").classList.add("d-none")
		handleResize();
	})
	function goMain(){
		document.querySelector(".masterplan").classList.add("d-none")
	}
	return (

		<section className={"primary masterplan "}>
			<div className="labels">
				<div className="labels__item master" id={"labels__item-master"}>
					<div className="inner">

						<div className="labels__title">Island 1</div>

					</div>
				</div>
			</div>


			<div className="inner">

				{ <Image className="main-image" src={require('../img/masterplan.webp')} width="1920" height={"1080"} placeholderSrc={require('../img/placeholder.webp')}/> }

				<div className="paths">
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
							<Link onClick={() => goMain()} className={"svg__link "} to={""} ><polygon className="cls-1" points="396 644 752 952 1043 716 671 495 396 644"></polygon></Link>
						</svg>
					</div>
				</div>



			</div>

		

		</section>

	);
}

export default withRouter(MasterPlanView);
import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import {Link,useParams,useHistory} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";
import {useTranslation} from "react-i18next";



function ClientLoginWait() {

  const {id} = useParams();

  const [fields, setFields] = useState({code:""});

  const { alert, setAlert } = useContext(AlertContext);
  const { client, setClient } = useContext(ClientContext);
  const { user, setUser } = useContext(UserContext);
  const { favs, setFavs } = useContext(FavContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [ minutes, setMinutes ] = useState(1);
  const [seconds, setSeconds ] =  useState(59);
  const [resendLinkDisable,setResendLinkDisable] = useState(false);

      useEffect(()=>{
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return ()=> {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if(client)
    {

      setFavs(client.favs);
      sessionStorage.setItem("favs",JSON.stringify(client.favs));

      apiConst.presentationCreate(client.user.id,client.id,0).then(result => {
        if(result.response)
        {
          sessionStorage.setItem("client",JSON.stringify(client));
          sessionStorage.setItem("presentation_id",result.presentation.id);
          sessionStorage.setItem("presentation_status",result.presentation.status);
          setAlert(t("hello") + " " + client.first_name + " " + client.last_name);
          history.push("/");


        }
      })
    }

  },[client]);

  useEffect(() => {
    if(user)
    {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("presentation_id");
      sessionStorage.removeItem("presentation_status");
      sessionStorage.removeItem("client");
      sessionStorage.removeItem("favs");
      sessionStorage.setItem("user",JSON.stringify(user));
    }

  },[user]);

  useEffect(()=> {
    let interval = setInterval(checkLogin,5*1000);
    return ()=> {
      clearInterval(interval);
    };
  },[])


  function checkLogin()
  {


      apiConst.clientLoginCheck(id).then(result => {
        console.log(result.response)
        if(result.response)
        {
          setUser(result.user);
          setClient(result.client);
          history.push("/");
        }
        else {



        }
      })

    }

  function resendInvitationLink()
  {
    if(!resendLinkDisable) {
      apiConst.clientLoginResend(id).then(result => {

        if(result.response)
        {
          setAlert(t("client_wait.login_resend_text"))
          setMinutes(1);
          setSeconds(59)
          setResendLinkDisable(true)
          setTimeout(()=> {
            setResendLinkDisable(false)
          },30000)
        }
        else {



        }
      })
    }
    else {
      setAlert(t("client_wait.wait_30_sec_for_resend"))
    }



  }





  return (
    <section className="sign-section">
    <div className="sign-wrap">

        <div className="inner">
          <h2 className={"text-uppercase"}>{t("client_wait.verify_email")}</h2>
          <p>{t("client_wait.verify_text")} </p>
          <div className={"text-center"}>
            { minutes === 0 && seconds === 0
                ? null
                : <h1> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1>
            }
          </div>
          <div className="mt-4 ">
            <div className={"alert alert-secondary text-center"}>
              {t("client_wait.spam_warning")}
            </div>
            <div className="seperator-text">
              {t("or")}
            </div>
          </div>
          <div className="mt-4">
            <button className={"default-button lined login mb-3 text-uppercase"} onClick={resendInvitationLink}>{t("client_wait.resend_link")}</button>
            <Link className={"default-button lined login text-uppercase"} to="/login">{t("go_back")}</Link>
          </div>



        </div>




    </div>
    </section>
  );
}

export default ClientLoginWait;
import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import '../css/FacilityView.css';
import {appConst} from "../constant/appConst";

import Viewer360 from "./Viewer360";


function RealShot(props) {

	const history = useHistory();
	return (

		<section className="primary facility" onContextMenu={(e) => e.preventDefault()}>

				<div>

					<button className={"close-button dark"}
							onClick={() => history.goBack()}>
						<div></div>
						<div></div>
					</button>


					<div>
						<Viewer360 link={"https://tr.iy.spn.wizio.co.uk/vr/"}/>
					</div>
				</div>


		</section>


)
	;
}

export default withRouter(RealShot);
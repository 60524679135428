import React, {useContext, useEffect, useState} from 'react';
import "../css/PreviewImage.css";
import {appConst} from "../constant/appConst";
const PreviewImage = ({imageObj}) => {
    const [facility,setFacility] = useState();

    useEffect(() => {

        setFacility(appConst.gallery.facilities.filter((item) => item.id === parseInt(imageObj.id))[0])


    },[imageObj]);

    useEffect(() => {
        if(!document.querySelector(".preview-image").classList.contains("show"))
        document.querySelector(".preview-image").classList.add("show");
    },[facility])

    return (
        <div className="preview-image" >
            {facility && <div>
                <div className="image-holder"><img className="main-image" src={facility.thumb} width={250} alt=""/></div>
                <div className="image-title">{facility.caption}</div>
            </div>}
        </div>
    )

};
// style={{left:window.innerWidth/2+imageObj.x,top:window.innerHeight/2+imageObj.y}}
export default PreviewImage;
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {appSettings} from "../constant/appConst";

const SubMenuItem = (props) => {

    const location = useLocation();
    const [isActive,setIsActive] = useState(false);

    useEffect(()=> {

        setIsActive(location.pathname === props.link)
    },[location])

    return (
        <div>
            {!props.isExternal && <Link to={props.link} className={isActive ? "submenu-item submenu-item--active" : "submenu-item"}>{props.title}</Link>}
            {props.isExternal && <Link to={{ pathname : props.link}} className="submenu-item" target="_blank">{props.title}</Link>}
        </div>


    );
};

export default SubMenuItem;
import React from 'react';
import {ReactComponent as MarkerIcon} from '../img/logo/map-marker.svg';
import {ReactComponent as MarkerPointIcon} from '../img/map-marker-point.svg';
const ProjectMarker = ({lat,lng}) => {
    return (
        <div >
            <MarkerIcon className="map-marker" lat={lat} lng={lng}/>
        </div>
    );
};

export default ProjectMarker;
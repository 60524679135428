import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {Link, useHistory,useParams} from "react-router-dom";
import "../css/FormResult.css";
import {useTranslation} from "react-i18next";



function FormResult() {

  const {message} = useParams();
  const [messageIcon,setMessageIcon] = useState("check");
  const [messageText,setMessageText] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(()=> {

    if(message === "forgotPassword") {
        setMessageIcon("check");
        setMessageText(t("form_result.forgot_password"));
    }

    else if(message === "invalidToken") {
      setMessageIcon("fail");
      setMessageText(t("form_result.invalid_reset_link"));
    }
    else if(message === "passwordUpdate") {
      setMessageIcon("check");
      setMessageText(t("form_result.password_success"));
    }
    else if(message === "invalidCode") {
      setMessageIcon("fail");
      setMessageText(t("form_result.verification_no_valid"));
    }
    else if(message === "thankYouRegister") {
      setMessageIcon("check");
      setMessageText(t("form_result.thank_you_register"));
    }
    else if(message === "invitationAccepted") {
      setMessageIcon("check");
      setMessageText(t("form_result.accepted_invitation"));
    }
    else if(message === "loginAccepted") {
      setMessageIcon("check");
      setMessageText(t("form_result.accepted_login"));
    }
    else {
       setMessageText("");
    }
  },[]);


  return (
    <section  className="sign-section">
      {(messageText && messageIcon) && <div className="sign-wrap">

        <div className="inner">
          <div className={"text-center"}>{messageIcon && <span className={"message-icon " + messageIcon}/>}</div>
          <h2 className="mt-3">{messageIcon === "check" ? t("form_result.success") : t("form_result.failed") }</h2>
          <p className="mt-3 text-center">{ messageText}</p>

          <div className="mt-4">
            {
              (()=>{
                if(!(message === "invitationAccepted" || message === "loginAccepted")) {
                  if(user) return <Link className={"default-button lined login text-uppercase"} to="/inviteClient">{t("invite_client")}</Link>;
                  if(!user) return <Link className={"default-button lined login text-uppercase"} to="/">{t("go_home")}</Link>
                }

              })()
            }


          </div>

        </div>

      </div>}

      {!user && <Link to="/salesLogin" className="sales-login-link">{t("login_as_sales_person")}</Link>}
      {user && <Link to="/" className="sales-login-link">{t("go_home")}</Link>}
    </section>
  );
}

export default FormResult;
import React, {useContext} from 'react';
import AlertContext from "./context/AlertContext";
import "../css/Alert.css";
const Alert = () => {
    const {alert,setAlert} = useContext(AlertContext);
    if(alert)
    {
        let alert = document.getElementById("simple-alert");
        alert.classList.add('show');
        setTimeout(() => {
            alert.classList.remove('show');
            alert.classList.add('hide');
            setTimeout(() => {
                setAlert(null);
                alert.classList.remove('hide');
            },500);
        },3000)
    }
    return (
        <div id="simple-alert" className="simple-alert">
            {alert}
        </div>
    );
};

export default Alert;
export const buildingConst = {
    "s1": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status: 1, level: 0, points: "141 813.5 1391 811.5 1393 885.5 141 903.5 141 813.5"},
            {status: 1, level: 1, points: "141 813.5 141 711.5 1396 743.5 1397 811.5 141 813.5"},
            {status: 1, level: 2, points: "141 711.5 142 613.5 1396 675.5 1396 743.5 141 711.5"},
            {status: 1, level: 3, points: "1396 609.5 1396 675.5 142 613.5 145 513.5 1396 609.5"},
            {status: 1, level: 4, points: "143 413.5 142 513.5 1285.96 600.43 1286 528.5 143 413.5"},
            {status: 1, level: 5, points: "141 413.5 141 313.5 1225 452.5 1225.03 523.02 141 413.5"},
            {
                status: 1,
                level: 6,
                points: "141 314.5 142 265.5 122 231.5 121 210.5 131 211.5 130 184.5 1022 328.5 1023 348.5 1029 349.5 1029 405.5 1217 431.5 1217.21 451.44 141 314.5"
            },
        ]
    },
    "s2": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status: 1, level: 0, points: "58 800.5 58 846.5 1480 857.5 1478 797.5 58 800.5"},
            {status: 1, level: 1, points: "52 734.5 1476 732.5 1478 797.5 52 800.5 52 734.5"},
            {status: 1, level: 2, points: "52 734.5 53 669.5 1485 664.5 1487 732.5 52 734.5"},
            {status: 1, level: 3, points: "53 669.5 53 605.5 1490 598.5 1491 664.5 53 669.5"},
            {status: 1, level: 4, points: "53 605.5 54 540.5 1355 529.5 1357 577.5 1483 576.5 1483.33 598.11 53 605.5"},
            {status: 1, level: 5, points: "54 540.5 54 476.5 1282 463.5 1283.47 529.68 54 540.5"},
            {status: 1, level: 6, points: "54 476.5 53 427.5 44 427.5 44 414.5 53 414.5 53 395.5 865 383.5 883 427.5 883 437.5 888 448.5 1277 444.5 1277 463.21 54 476.5"},
        ]
    },

}

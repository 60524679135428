import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

const PointMarker = (props) => {
    const history = useHistory();
    useEffect(()=> {
        setTimeout(()=> {

            if(document.querySelector("#map-marker-point" + props.markerId))
            document.querySelector("#map-marker-point" + props.markerId).classList.add("loaded")
        },400 + Math.floor(Math.random()*400))

    },[])
    return (
        <div onClick={() => {
            if(props.markerId != 0) {
                history.push(props.link);
                //props.onMarkerClick(props.lat, props.lng, props.markerId);
            }
        }}>
            <div id={"map-marker-point" + props.markerId} className={props.color ? "map-marker-point "+props.color : "map-marker-point" } lat={props.lat} lng={props.lng}></div>
        </div>
    );
};

export default PointMarker;
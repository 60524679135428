import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import {Link,useParams,useHistory} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";
import {useTranslation} from "react-i18next";



function ClientVerification() {

  const {id} = useParams();
  const [fields, setFields] = useState({code:""});
  const [errors, setErrors] = useState({code:""});
  const [isLoadingForm,setIsLoadingForm] = useState(false);
  const [isLoadingResend,setIsLoadingResend] = useState(false);
  const [count,setCount] = useState(0);
  const { alert, setAlert } = useContext(AlertContext);
  const { client, setClient } = useContext(ClientContext);
  const { user, setUser } = useContext(UserContext);
  const { favs, setFavs } = useContext(FavContext);
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    if(client)
    {

      setFavs(client.favs);
      sessionStorage.setItem("favs",JSON.stringify(client.favs));

      apiConst.presentationCreate(client.user.id,client.id).then(result => {
        if(result.response)
        {
          sessionStorage.setItem("client",JSON.stringify(client));
          sessionStorage.setItem("presentation_id",result.presentation.id);
          setAlert(t("hello")+" " + client.first_name + " " + client.last_name);
          history.push("/");

        }
      })
    }

  },[client]);

  useEffect(() => {
    if(user)
    {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("presentation_id");
      sessionStorage.removeItem("client");
      sessionStorage.removeItem("favs");
      sessionStorage.setItem("user",JSON.stringify(user));
    }

  },[user]);

  function onClickVerify(){
    if(handleValidation())
    {
      setIsLoadingForm(true);
      apiConst.clientVerify(fields["code"],id).then(result => {
        setIsLoadingForm(false);

        if(result.response)
        {
          setUser(result.client.user);
          setClient(result.client);

        }
        else
        {
          errors["code"] = t("client_verification.verification_code_invalid");
          setErrors(errors);

        }

      })
    }

  }

  function onClickResendCode(){
    let resendResultText = document.querySelector(".resend-result-text");
    resendResultText.innerHTML = "";
    setIsLoadingResend(true);
      apiConst.clientResendCode(id).then(result => {
        setIsLoadingResend(false);

        if(result.response)
        {
            setCount(60);
            resendResultText.innerHTML = t("client_verification.verification_code_success");

        }
        else
        {
          resendResultText.innerHTML = t("client_verification.wait_2_min_for_resend");
        }
        resendResultText.classList.remove("d-none");

      })


  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["code"])
    {
      formIsValid = false;
      errors["code"] = t("client_verification.type_verification_code");
    }


    setErrors(errors)
    return formIsValid;
  }

  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }




  return (
    <section className="sign-section">
    <div className="sign-wrap">

        <div className="inner">
          <h2 className={"text-uppercase"}>{t("client_verification.verify_account")}</h2>
          <div className="form-element">
            <input type="text" className={errors["code"] ? "form-control is-invalid" : "form-control"}  id="code" placeholder={t("client_verification.verification_code")} onChange={(e) => handleChange("code",e)} />
            {
              errors["code"] && <div className="invalid-feedback">
                {errors["code"]}
              </div>
            }
          </div>


          <div className="mt-4">
            <ButtonWithLoading className={"default-button login"} onClick={onClickVerify} label={t("client_verification.verify")} isLoading={isLoadingForm} />
          </div>

          <div className="mt-4">
            {<ButtonWithLoading className={"default-button lined resendCode"} onClick={onClickResendCode} label={t("client_verification.resend_code")} isLoading={isLoadingResend} />}
            <p className={"mt-3 text-center resend-result-text d-none"}></p>
          </div>
          <div className="mt-4 ">
            <div className="seperator-text">
              {t("or")}
            </div>
          </div>
          <div className="mt-4">
            <Link className={"default-button lined login"} to="/login">{t("login")}</Link>
          </div>



        </div>




    </div>
    </section>
  );
}

export default ClientVerification;
import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import PropertyPlan from "./PropertyPlan";
import {appUtil} from "../../constant/appUtil";
import {appConst} from "../../constant/appConst";
import FavContext from "../context/FavContext";
import {Image} from "react-img-placeholder";

function FloorPlan({appData,properties,floorId,planSvg,floorCode}) {

    const [plan,setPlan] = useState([]);
    const {favs, setFavs} = useContext(FavContext);
    useEffect(()=>{

        if(properties.length)
        {
            let favProps = [];

            favs.forEach((fav) => {
                favProps.push(fav.property.number)
            })
            let updatedPlan = []
            properties.forEach(function(property,i){

                if(property.flat_level === parseInt(floorId))
                {
                    let statusLabel = appUtil.convertToSlug(appUtil.convertStatusIdToLabel(property.status));
                    let className = "item " + statusLabel;
                    if(appData.isFilterModeActive)
                    {
                        className = "item disabled " +  statusLabel;
                        appData.filteredProperties.forEach((filteredProp) => {
                            if(filteredProp.id === property.id)
                            {
                                className = "item filtered " + statusLabel;
                            }

                        })
                    }
                    let link = "/property-view/" + property.id;
                    let title = property.number;
                    let roomType = property.room_type.name;
                    let svgValue = parseInt(property.is_dublex) ? planSvg.propertyPlan.find(plan => (plan.number === property.number && plan.isDublex)) :planSvg.propertyPlan.find(plan => plan.number === property.number);

                    updatedPlan.push (<PropertyPlan key={i} isFav={favProps.includes(property.code)} link={link} className={className} svgValue={svgValue} title={title} roomType={roomType}/>)
                }
            });

            setPlan(updatedPlan);
        }


    },[properties,appData.isFilterModeActive,appData.filteredProperties]);

    function isInFiltered(property){

        return false;
    }

    return (
        <div className={"floor-plan-container"}>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox={planSvg.viewBox}>

                {plan}
                <path id="Layer3_0_1_STROKES" data-name="Layer3 0 1 STROKES" className="cls-1" d={planSvg.floorPlan}/>


            </svg>
            <img src={require('../../img/property_floorplan/' + floorCode + '.webp')}/>
        </div>


    );

}

export default FloorPlan;
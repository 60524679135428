import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import '../css/FacilityView.css';
import {appConst} from "../constant/appConst";
import {propertyConst} from "../constant/propertyConst";
import {appUtil} from "../constant/appUtil";
import Viewer360 from "./Viewer360";


function BuildingView(props) {
	const [facility,setFacility] = useState();
	const { id } = useParams();
	const history = useHistory();
	useEffect(() => {

		setFacility(appConst.gallery.facilities.filter((item) => item.id === parseInt(id))[0])

	},[]);



	return (

		<section className="primary facility" onContextMenu={(e)=> e.preventDefault()}>
			{facility &&
			<div>
				<div className={facility.darktext ? "main-title dark" : "main-title"}>
					{ !facility.vr && <div className="number">Facility / {facility.caption}</div> }

				</div>


					<button className={facility.darktext ? "close-button dark" : "close-button"} onClick={() => history.goBack()}>
						<div></div>
						<div></div>
					</button>


				<div className="inner">
					{ !facility.vr && <img className="main-image" src={facility.source} alt=""/> }
				</div>


				<div>
					{facility.vr && <Viewer360 link={facility.vr}/>}
				</div>
			</div>}



		

		</section>

	);
}

export default withRouter(BuildingView);
import React, {useState, useContext, useEffect} from 'react';
import {
    Link, withRouter,
} from "react-router-dom";
import '../css/FilterSubMenu.css';
import FilterContext from "./context/FilterContext.js";
import AppDataContext from "./context/AppDataContext";
import {appUtil} from "../constant/appUtil";
import {appSettings} from "../constant/appConst";
import FilterTag from "./FilterTag";
import SettingsContext from "./context/SettingsContext";
import {useTranslation} from "react-i18next";
import LocationContext from "./context/LocationContext";
import SearchMenuItem from "./SearchMenuItem";

function FilterSubMenu(props) {

    const { settings, setSettings } = useContext(SettingsContext);
    const { filter, setFilter } = useContext(FilterContext);
    const { appData, setAppData } = useContext(AppDataContext);
    const [filterStatusTag,setFilterStatusTag] = useState(false)
    const priceUnit = appSettings.priceFilter.unit;
    const [sliderValueMin,setSliderValueMin] = useState(filter.priceMin/priceUnit);
    const [sliderValueMax,setSliderValueMax] = useState(filter.priceMax/priceUnit);
    const { t } = useTranslation();
    const [listItems,setListItems] = useState(null);

    useEffect(()=> {
        if(appData.loaded)
        {
            let rows = [];
            let sorted = appData.filteredProperties.sort(appUtil.dynamicSort("code"));
            sorted.forEach(function(property){
                rows.push(<SearchMenuItem title={property.code} status={property.status_id} link={"/property-view/" + property.id} key={property.id} />);
            })
            setListItems(rows)
        }
    },[appData])

    useEffect(() => {

    },[sliderValueMin,sliderValueMax])

    function ChangeStatus(status){

        let newFilter = {...filter};
        newFilter.status = status;
        setFilter(newFilter);

    }

    function ChangeFloorType(floorType){

        let newFilter = {...filter};
        const index = newFilter.floorType.indexOf(floorType);
        if (index > -1) {
            newFilter.floorType.splice(index, 1);
        }
        else {
            newFilter.floorType.push(floorType);
        }

        setFilter(newFilter);

    }

    function ChangeBuildingType(buildingType){

        let newFilter = {...filter};
        const index = newFilter.buildingType.indexOf(buildingType);
        if (index > -1) {
            newFilter.buildingType.splice(index, 1);
        }
        else {
            newFilter.buildingType.push(buildingType);
        }

        setFilter(newFilter);

    }

    function ChangeGardenArea(gardenArea){

        let newFilter = {...filter};
        newFilter.gardenArea = gardenArea;
        setFilter(newFilter);

    }



    function ChangePrice(type,price){

        let newFilter = {...filter};
        if(type === "min") newFilter.priceMin = price;
        if(type === "max") newFilter.priceMax = price;
        setFilter(newFilter);

    }


    useEffect(() =>{
        if(appData.isFilterModeActive)
        updateFilteredProperties()
        console.log(filter)

    },[filter])



    function updateFilteredProperties()
    {
        if(appData.loaded) {
            let newAppData = {...appData};
            newAppData.filteredProperties = appData.properties.filter(function(p) {
                if(filter.floorType.length)
                {
                    if( (!filter.floorType.includes(p.room_type_id))) return false;
                }
                if(filter.gardenArea !== -1)
                {

                    if(filter.gardenArea === 1)
                    {
                        if(p.garden_area === 0)
                        {
                            return false;
                        }

                    }

                    else
                    {
                        if(p.garden_area > 0 )
                        {
                            return false;
                        }
                    }



                }
                if(filter.status)
                {
                    if(p.status !== parseInt(filter.status)) return false;
                }

                if(filter.buildingType.length)
                {
                    if( (!filter.buildingType.includes(p.building_type_id))) return false;
                }

                if(filter.priceMin && filter.priceMax)
                {

                    if(p.price < filter.priceMin || p.price > filter.priceMax) return false;

                }
                return true;
            });

            setAppData(newAppData);
        }

    }

    function onClickButtonClear(){

        document.getElementById("filter-floor-type").reset();
        document.getElementById("filter-garden-area").reset();
        document.getElementById("filter-status").reset();
        document.getElementById("filter-building-type").reset();
        setSliderValueMin(appSettings.priceFilter.min / priceUnit)
        setSliderValueMax(appSettings.priceFilter.max / priceUnit)
        setFilter({
            floorType: [],
            buildingType : [],
            gardenArea : -1,
            priceMin: appSettings.priceFilter.min,
            priceMax: appSettings.priceFilter.max,
            status: false
        });

        let newAppData = {...appData};
        newAppData.filteredProperties = newAppData.properties;
        setTimeout(()=> {setAppData(newAppData);},100)



    }

    function getLabel(type,id) {
        if(type === "status") {
            let label = "";
            appData.statuses.forEach(status => {
                if(status.id === id) label = status.name
            })
            return label;

        }
        if(type === "gardenArea") {
            let label = "";
            if(id === 1) label = t("filter.terrace_exist");
            if(id === 0) label = t("filter.terrace_not_exist");

            return label;

        }
        if(type === "floorType") {
            let label = "";
            appData.roomTypes.forEach(room => {
                if(room.id === id) label = room.name
            })
            return label;

        }

        if(type === "buildingType") {
            let label = "";
            appData.buildingTypes.forEach(building => {
                if(building.id === id) label = building.name
            })
            return label;

        }

    }



    function onClickBuildingTypesToggle(){
        let filterForm = document.querySelector("#filter-building-type")
        filterForm.classList.toggle("d-none")
        let filterToggleBtn = document.querySelector(".filter-building-type-toggle-btn")
        filterToggleBtn.querySelector(".icon").classList.toggle("icon-up")
        filterToggleBtn.querySelector(".icon").classList.toggle("icon-down")
    }





  return (

      <div className="filter-menu side-menu">
          <div className="search-list-container">
              <div className="search-total">
                  {listItems ? listItems.length : 0}
              </div>
              <div className="search-list">

                  {listItems}


              </div>
          </div>

          <div className="inner">
              <button className={"close-button"} onClick={props.onClickClose}>
                  <div></div>
                  <div></div>
              </button>
              <div className={"title"}>
                  <h2>{t("search.title")}</h2>
              </div>
              <h3>{t("filter.building_types")}
                  <button onClick={onClickBuildingTypesToggle} className="filter-building-type-toggle-btn"><span
                      className="icon icon-up"></span></button>
              </h3>
              <section>

                  <form className="floor-type" id="filter-building-type">
                      <div className="row no-gutters">
                          {appData.buildingTypes && appData.buildingTypes.map(building => (
                              <div className="col-6 " key={building.id}>
                                  <div className="form-group">
                                      <label className="check-container">{building.name}
                                          <input type="checkbox" id={building.name} name="building" value={building.id}
                                                 checked={filter.buildingType.includes(building.id)} onChange={() => {
                                              ChangeBuildingType(building.id);
                                          }}/>
                                          <span className="checkmark"/>
                                      </label>
                                  </div>

                              </div>

                          ))}
                      </div>
                  </form>

              </section>
              <h3>{t("filter.garden_filter")}
              </h3>
              <section>

                  <form className="floor-type" id="filter-garden-area">
                      <div className="row no-gutters">

                          <div className="col-4 ">
                              <div className="form-group">
                                  <label className="check-container">{ t("filter.all")}
                                      <input type="checkbox" name="gardenArea" value="false"
                                             checked={filter.gardenArea === -1}
                                             onChange={() => {
                                                 ChangeGardenArea(-1);
                                             }}/>
                                      <span className="checkmark"/>
                                  </label>
                              </div>

                          </div>

                          <div className="col-4 ">
                              <div className="form-group">
                                  <label className="check-container">{ t("filter.exist")}
                                      <input type="checkbox" name="gardenArea" value="false"
                                             checked={filter.gardenArea === 1}
                                             onChange={() => {
                                                 ChangeGardenArea(1);
                                             }}/>
                                      <span className="checkmark"/>
                                  </label>
                              </div>

                          </div>

                          <div className="col-4 ">
                              <div className="form-group">
                                  <label className="check-container">{ t("filter.not_exist")}
                                      <input type="checkbox" name="gardenArea" value="false"
                                             checked={filter.gardenArea === 0}
                                             onChange={() => {
                                                 ChangeGardenArea(0);
                                             }}/>
                                      <span className="checkmark"/>
                                  </label>
                              </div>

                          </div>


                      </div>
                  </form>

              </section>
              <h3>{t("filter.floor_types")}</h3>
              <section>

                  <form className="floor-type" id="filter-floor-type">
                      <div className="row no-gutters">
                          {appData.roomTypes && appData.roomTypes.map(room => (
                              <div className="col-6" key={room.id}>
                                  <div className="form-group">
                                      <label className="check-container">{room.name}
                                          <input type="checkbox" id={room.name} name="room" value={room.id}
                                                 checked={filter.floorType.includes(room.id)} onChange={() => {
                                              ChangeFloorType(room.id);
                                          }}/>
                                          <span className="checkmark"/>
                                      </label>
                                  </div>

                              </div>

                          ))}
                      </div>
                  </form>

              </section>

              {appSettings.showPrices &&
                  <div>
                      <h3>{t("filter.price_range")}</h3>
                      <section className="price-range">

                          <div>
                              <div className="price-display">
                                  <span>{appUtil.formatPrice(sliderValueMin * priceUnit)}</span> - <span>{appUtil.formatPrice(sliderValueMax * priceUnit)}</span>
                              </div>
                              <div className="price-slider-container mt-2">
                                  <input
                                      type="range"
                                      id="filter-price-range-min"
                                      name="price-range-min"
                                      min={appSettings.priceFilter.min / appSettings.priceFilter.unit}
                                      max={appSettings.priceFilter.max / appSettings.priceFilter.unit}
                                      value={sliderValueMin}
                                      onChange={(e) => {

                                          let value = parseInt(e.target.value);

                                          if (value < sliderValueMax - 2) {
                                              setSliderValueMin(value);
                                              ChangePrice("min", value * priceUnit);
                                          }

                                      }}
                                  />

                                  <input
                                      type="range"
                                      id="filter-price-range-max"
                                      name="price-range-max"
                                      min={appSettings.priceFilter.min / appSettings.priceFilter.unit}
                                      max={appSettings.priceFilter.max / appSettings.priceFilter.unit}
                                      value={sliderValueMax}
                                      onChange={(e) => {

                                          let value = parseInt(e.target.value);

                                          if (value > sliderValueMin + 2) {
                                              setSliderValueMax(value);
                                              ChangePrice("max", value * priceUnit);
                                          }

                                      }}
                                  />
                              </div>

                          </div>

                      </section>
                  </div>

              }
              <h3>{t("filter.status")}</h3>
              <section>
                  <form className="status" id="filter-status">
                      <div className="row no-gutters">
                          {appData.statuses && appData.statuses.map(status => (
                              <div className="col-6" key={status.id}>
                                  <div className="form-group">
                                      <label className="check-container">{status.name}
                                          <input type="radio" id={"status_" + status.name.toLowerCase()} name="status"
                                                 value={status.id} checked={filter.status === status.id}
                                                 onChange={() => {
                                                     ChangeStatus(status.id);
                                                 }}/>
                                          <span className="checkmark"></span>
                                      </label>
                                  </div>
                              </div>
                          ))}
                      </div>
                  </form>
              </section>


              <h3>{t("filter.view")}</h3>
              <section>
                  <Link to="/map-view">
                      <button
                          className={props.location.pathname === "/map-view" ? "button-view map active" : "button-view map"}>
                          <span></span>
                          {t("filter.map")}
                      </button>
                  </Link>
                  <Link to="/list-view">
                      <button
                          className={props.location.pathname === "/list-view" ? "button-view list active" : "button-view list"}>
                          <span></span>
                          {t("filter.list")}
                      </button>
                  </Link>
              </section>
              <h3>{t("filter.tags")}</h3>
              <section className={"filter-tag-container"}>
                  <button className="default-button button-clear"
                          onClick={onClickButtonClear}>{t("filter.clear")}</button>
                  {filter.status && <FilterTag type={"status"} text={getLabel("status", filter.status)}/>}
                  {filter.gardenArea !== -1 &&
                      <FilterTag type={"gardenArea"} text={getLabel("gardenArea", filter.gardenArea)}/>}
                  {filter.buildingType.length > 0 && filter.buildingType.map((item, i) => (
                      <FilterTag type={"buildingType"} key={i} text={getLabel("buildingType", item)} buildingId={item}/>
                  ))}
                  {filter.floorType.length > 0 && filter.floorType.map((item, i) => (
                      <FilterTag type={"floorType"} key={i} text={getLabel("floorType", item)} floorId={item}/>
                  ))}
                  {filter.priceMin !== appSettings.priceFilter.min &&
                      <FilterTag type={"priceMin"} text={"Min:" + appUtil.formatPrice(filter.priceMin)}
                                 stateChanger={setSliderValueMin}/>}
                  {filter.priceMax !== appSettings.priceFilter.max &&
                      <FilterTag type={"priceMax"} text={"Max:" + appUtil.formatPrice(filter.priceMax)}
                                 stateChanger={setSliderValueMax}/>}
              </section>

          </div>


      </div>

  );
}

export default withRouter(FilterSubMenu);
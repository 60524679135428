import React from 'react';
import "../css/Viewer360.css";
function Viewer360(props) {
    return (
        <div className={"viewer360"}>
            <iframe src={props.link} frameBorder="0" allowFullScreen={true} />
        </div>
    );
}

export default Viewer360;
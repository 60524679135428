import React, {useContext} from 'react';
import "../css/ClientMenu.css";
import UserContext from "./context/UserContext";
import {useHistory,withRouter} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import ConfirmContext from "./context/ConfirmContext";
import FavContext from "./context/FavContext";
import {apiConst} from "../constant/apiConst";
import AlertContext from "./context/AlertContext";
import {appSettings} from "../constant/appConst";
import {appUtil} from "../constant/appUtil";
import {useTranslation} from "react-i18next";

const UserMenu = (props) => {
    const { user, setUser } = useContext(UserContext);
    const { client, setClient } = useContext(ClientContext);
    const {confirm,setConfirm} = useContext(ConfirmContext);
    const {favs,setFavs} = useContext(FavContext);
    const {alert,setAlert} = useContext(AlertContext);
    const history = useHistory();
    const { t } = useTranslation();
    function onClickLogout(){
        if(appUtil.isSelfPresentation())
            setConfirm({show:true,text:t("terminate_session_client"),onConfirm : terminateSession})
        else
            setConfirm({show:true,question:t("send_presentation_details_title"),text:t("send_presentation_details_text"),onConfirm : sendPresentationDetails, onUnconfirm: sendPresentationDetails})
    }

    function sendPresentationDetails() {
        setConfirm({show:true,text:t("terminate_session_client"),onConfirm : terminateSession})
        apiConst.presentationDetailsMail(client.id).then(result => {
            if(result.response)
            {
                setAlert(t("send_presentation_details_success"))
            }
        })

    }

    function terminateSession(){
        apiConst.presentationTerminate(sessionStorage.getItem("presentation_id"));
        setClient(null);
        setFavs([]);
        sessionStorage.removeItem("presentation_id");
        if(appUtil.isSelfPresentation()) {
            setUser(null);
            sessionStorage.removeItem("user");
        }
        sessionStorage.removeItem("presentation_status");
        sessionStorage.removeItem("client");
        sessionStorage.removeItem("favs");
        history.push("/");
        setAlert(t("activity_terminated"));
    }


    function onCloseBtnClick() {
        document.querySelector(".client-menu").classList.add("d-none")
    }

    function onClickFavorites(){
        document.querySelector(".client-menu").classList.add("d-none")
        history.push("/favorites");
    }

    return (
        <div className={"client-menu d-none"}>
            <button className={"close-button colored"} onClick={onCloseBtnClick}>
                <div></div>
                <div></div>
            </button>
            <div className="sub-client-menu ">
                <div className={"sub-client-menu-icon-container"}>
                    <div className="sub-client-menu-icon"></div>
                </div>
                <span className={"client-online text-uppercase"}><span className={"client-online-indicator"}></span>{t("online")}</span>
                <div className={"mt-3"}>
                    <div className="client-menu-role">{t("client")}</div>
                    <div className="client-menu-name">{client.first_name + " " + client.last_name}</div>
                </div>
            </div>
            <div className={"client-menu-link-container"}>
                <div onClick={onClickFavorites} className={"client-menu-link dashboard-link"}>
                    <button className="default-button" >{t("favorites.title")}</button>
                </div>
                <div onClick={onClickLogout} className={"client-menu-link logout-link"}>
                    <button className="default-button" >{t("logout")}</button>
                </div>
            </div>
        </div>
    );
};

export default withRouter(UserMenu);
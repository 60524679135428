import React, {useEffect} from 'react';
import "../css/IntroVideo.css";
import video from "../img/video/wizio.mp4";

function IntroVideo() {

    function onSkipClick() {
        document.getElementById("intro-video").classList.add("hide-intro")
    }
    useEffect(() => {

        document.getElementById("wizio").onended = function() {
            document.getElementById("intro-video").classList.add("hide-intro")
        };

    },[])

    return (
        <div id="intro-video" className="intro-video" onContextMenu={(e)=> e.preventDefault()}>
            <video id="wizio" autoPlay={true} muted={true}>
                <source src={video} type="video/mp4"/>
            </video>
            <button id="skip-button" onClick={onSkipClick}>SKIP</button>
        </div>
    );
}

export default IntroVideo;
import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import {Link,useParams,useHistory} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";



function ClientLoginAccept() {

  const {id,code} = useParams();
  const history = useHistory();


  useEffect(()=> {
    apiConst.clientLoginAccept(id,code).then(result => {

      if(result.response)
      {
        history.push("/formResult/loginAccepted")
      }
      else
      {
        history.push("/formResult/invalidCode")
      }

    })
  },[])

  return (
    <div />
  );
}

export default ClientLoginAccept;
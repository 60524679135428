import React from "react";
import i18next from 'i18next';

const appSettings = {
    projectName: "Smyrna Park Nefes",
    projectId : 1,
    currencyUnit: "₺",
    currencyUnitPosition: "before",
    areaUnit: <>m<sup>2</sup></>,
    showPrices: true,
    priceFilter: {
        unit: 1000000,
        min: 500000000,
        max: 2000000000,
    },
    showIntroVideo : false,
    is360External : false,
    clientVerification : 0,
    showRegisterClientPage : true,
    botSalesRep : "online@ideayapi.com",
    isMultiCanvas : false,
    has360 : false,
    hasInfo : false,
    hasRealShot : true
};

i18next.on('languageChanged', function(lng) {

    appConst.menu.gallery = [
        {id:1, title: i18next.t("gallery.exterior"),isExternal : false,link: '/gallery/exterior'},
        {id:2, title: i18next.t("gallery.interior"),isExternal : false,link: '/gallery/interior'},
        {id:3, title: i18next.t("gallery.social"),isExternal : false,link: '/gallery/social'},
        {id:4, title: i18next.t("gallery.brochure"),isExternal : false,link: '/gallery/brochure'},
    ];

    appConst.menu.location_category = [
        {id : "transportation",title : i18next.t("location.transportation")},
        {id : "health",title : i18next.t("location.health")},
        {id : "schools",title : i18next.t("location.schools")},
        {id : "market",title : i18next.t("location.market")},
        {id : "social",title : i18next.t("location.social")},
        {id : "avm",title : i18next.t("location.avm")},
        {id : "industry",title : i18next.t("location.industry")},
    ];



        appConst.gallery = {

        exterior : [
            {caption:i18next.t("gallery.exterior") + ' 1', source: require("../img/gallery/exterior/i1.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 2', source: require("../img/gallery/exterior/i2.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 3', source: require("../img/gallery/exterior/i3.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 4', source: require("../img/gallery/exterior/i4.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 5', source: require("../img/gallery/exterior/i5.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 6', source: require("../img/gallery/exterior/i6.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 7', source: require("../img/gallery/exterior/i7.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 8', source: require("../img/gallery/exterior/i8.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 9', source: require("../img/gallery/exterior/i9.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 10', source: require("../img/gallery/exterior/i10.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 11', source: require("../img/gallery/exterior/i11.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 12', source: require("../img/gallery/exterior/i12.jpg")},
        ],
            interior : [
            {caption:i18next.t("gallery.interior") + ' 1', source: require("../img/gallery/interior/i1.jpg")},
            {caption:i18next.t("gallery.interior") + ' 2', source: require("../img/gallery/interior/i2.jpg")},
            {caption:i18next.t("gallery.interior") + ' 3', source: require("../img/gallery/interior/i3.jpg")},
            {caption:i18next.t("gallery.interior") + ' 4', source: require("../img/gallery/interior/i4.jpg")},
            {caption:i18next.t("gallery.interior") + ' 5', source: require("../img/gallery/interior/i5.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i6.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i7.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i8.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i9.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i10.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i11.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i12.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i13.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i14.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i15.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i16.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i17.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i18.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i19.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i20.jpg")},

        ],

            social : [
            {caption:i18next.t("gallery.interior"), source: require("../img/gallery/social/i1.jpg")},
            {caption:i18next.t("gallery.pool"), source: require("../img/gallery/social/i2.jpg")},
            {caption:i18next.t("gallery.jacuzzi"), source: require("../img/gallery/social/i3.jpg")},
            {caption:i18next.t("gallery.cafe"), source: require("../img/gallery/social/i4.jpg")},
            {caption:i18next.t("gallery.sauna"), source: require("../img/gallery/social/i5.jpg")},
            {caption:i18next.t("gallery.spa"), source: require("../img/gallery/social/i6.jpg")},
            {caption:i18next.t("gallery.gym"), source: require("../img/gallery/social/i7.jpg")},

        ]
    }
})
const appConst = {
    
    menu: {
        gallery : [
            {id:1, title: i18next.t("gallery.exterior"),isExternal : false,link: '/gallery/exterior'},
            {id:2, title: i18next.t("gallery.interior"),isExternal : false,link: '/gallery/interior'},
            {id:3, title: i18next.t("gallery.social"),isExternal : false,link: '/gallery/social'},
            {id:4, title: i18next.t("gallery.brochure"),isExternal : false,link: '/gallery/brochure'},
        ],

        location_category : [
            {id : "transportation",title : i18next.t("location.transportation")},
            {id : "health",title : i18next.t("location.health")},
            {id : "schools",title : i18next.t("location.schools")},
            {id : "market",title : i18next.t("location.market")},
            {id : "social",title : i18next.t("location.social")},
            {id : "avm",title : i18next.t("location.avm")},
            {id : "industry",title : i18next.t("location.industry")},

        ],

        location : {
            center : [
                {id:0, title:"", lat: 38.53749667304882,  lng:27.03404151382979}
            ],


            transportation : [
                {id:1,title:"İzban Ulukent Durağı", link :"/location/transportation/1",lat:38.54813049952319,  lng:27.035792342403386},
                {id:2,title:"İzban Egekent Durağı", link :"/location/transportation/2",lat:38.5074324317222 , lng:27.045926806232007},
                {id:3,title:"İzban Egekent 2 durağı", link :"/location/transportation/3",lat:38.56072778801216 , lng:27.044439999999998},
                {id:4,title:"İzban Ata Sanayi durağı", link :"/location/transportation/4",lat:38.499299934741714 , lng:27.05294882072096},
                {id:5,title:"Bostanlı vapur iskelesi", link :"/location/transportation/5",lat:38.45401223869715 , lng:27.0975573},
                {id:6,title:"Karşıyaka vapur iskelesi", link :"/location/transportation/6",lat:38.455351421877154 , lng:27.12013451835614},
                {id:7,title:"9 Eylül Otobüs Durağı", link :"/location/transportation/7",lat:38.540325295284845 , lng:27.03394904250466},
                {id:8,title:"Kuzey Ege Otoyolu", link :"/location/transportation/8",lat:38.57093976417001 , lng:27.057175000000004},

            ],

            health : [
                {id:9,title:"Menemen Devlet Hastanesi", link :"/location/health/9",lat:38.607808156488 , lng:27.08787646434022},
                {id:10,title:"Kent Hastanesi", link :"/location/health/10",lat:38.489942337598926 , lng:27.05200682975591},
                {id:11,title:"Bakırçay Üniversitesi Hastanesi", link :"/location/health/11",lat:38.49980063014955 , lng:27.04945489188462},
                {id:12,title:"İzmir Şehir Hastanesi", link :"/location/health/12",lat:38.48460826385075 , lng:27.17772455767268},
                {id:13,title:"Medikal Point", link :"/location/health/13",lat:38.47396849035623 , lng:27.11190312883634},
                {id:14,title:"Metropol Karşıyaka", link :"/location/health/14",lat:38.47002851480812,  lng:27.113517787761158},
                {id:15,title:"Metropol Şemikler", link :"/location/health/15",lat:38.47802482358679 , lng:27.096839851172213},
                {id:16,title:"Başkent Hastanesi", link :"/location/health/16",lat:38.466711240547454 , lng:27.09331224156821},
                {id:17,title:"Ekol", link :"/location/health/17",lat:38.48564064488126 , lng:27.059709190540715},
                {id:18,title:"Özel Can Hastanesi", link :"/location/health/18",lat:38.483187068874535 , lng:27.0582212495573},
                {id:19,title:"Çiğli Bölge Eğitim Hastanesi", link :"/location/health/19",lat:38.49947076886491 , lng:27.0508365627253},
                {id:20,title:"Sada Hastanesi", link :"/location/health/20",lat:38.59185820109055 , lng:27.074242786509004},
                {id:21,title:"Can’s Veteriner Kliniği", link :"/location/health/21",lat:38.54836952600597 , lng:27.045780871163664},
                {id:22,title:"MarkaVet Veteriner Kliniği", link :"/location/health/22",lat:38.52237117751671 , lng:27.043409399999998},
                {id:23,title:"Kiko Veteriner Kliniği", link :"/location/health/23",lat:38.55283149677668 , lng:27.048747128836332},

            ],

            schools : [
                {id:24,title:"Gelişim Koleji", link :"/location/schools/24",lat:38.5425739303833 , lng:27.03319807839363},
                {id:25,title:"Sınav Koleji", link :"/location/schools/25",lat:38.51776121804781 , lng:27.042526207512164},
                {id:26,title:"İzmir Katip Çelebi Üniversitesi", link :"/location/schools/26",lat:38.51678099283183 , lng:27.031243426670176},
                {id:27,title:"Mavişehir 3 Doğa Koleji", link :"/location/schools/27",lat:38.49402985485061 , lng:27.050108076539274},
                {id:28,title:"Karşıyaka Kent koleji", link :"/location/schools/28",lat:38.5146216955909 , lng:27.0441851},
                {id:29,title:"Egekent 2 Ortaokulu", link :"/location/schools/29",lat:38.55563970988568 , lng:27.044513078393635},
                {id:30,title:"Ulukent Ortaokulu", link :"/location/schools/30",lat:38.53832144030617 , lng:27.040109328836333},
                {id:31,title:"Bilnet Okulları", link :"/location/schools/31",lat:38.547934147970615 , lng:27.028584211898426},
                {id:32,title:"Küçük Yıldızlar Anaokulu", link :"/location/schools/32",lat:38.5387920043753 , lng:27.034343156651758},
                {id:33,title:"İzbilim Koleji", link :"/location/schools/33",lat:38.55869808239813 , lng:27.052791199999998},
                {id:34,title:"Büyük Çiğli Özel Türk Anaokulu", link :"/location/schools/34",lat:38.484265008079596 , lng:27.057856129641987},
                {id:35,title:"Özel Türk Koleji BüyükÇiğli Kampüsü", link :"/location/schools/35",lat:38.485634729910444 , lng:27.05736930210498},
                {id:36,title:"Orantı Koleji", link :"/location/schools/36",lat:38.48793295571461 , lng:27.078264349557298},
                {id:37,title:"Çiğli Final Okulları", link :"/location/schools/37",lat:38.49098834823696 , lng:27.077557420720954},
                {id:38,title:"İzmir Özel Denge Koleji", link :"/location/schools/38",lat:38.488920451084766 , lng:27.073479499999998},
                {id:39,title:"Özel Tanı Koleji", link :"/location/schools/39",lat:38.51574019298239 , lng:27.04318662883633},
                {id:40,title:"Bahçeşehir Koleji", link :"/location/schools/40",lat:38.47476955458085 , lng:27.068011381144586},
                {id:41,title:"Bakırçay Üniversitesi", link :"/location/schools/41",lat:38.5822714246031 , lng:26.9637831495573},
                {id:42,title:"İzfen Koleji", link :"/location/schools/42",lat:38.48414545715392 , lng:27.039749580230964},

            ],

            market : [
                {id:43,title:"CarrefourSA", link :"/location/market/43",lat:38.53839042960629 , lng:27.03735236456969},
                {id:44,title:"Şok", link :"/location/market/44",lat:38.53901706118464 , lng:27.03394874399421},
                {id:45,title:"A101", link :"/location/market/45",lat:38.52124891887621 , lng:27.04211791574815},
                {id:46,title:"Migros", link :"/location/market/46",lat:38.538989458858694 , lng:27.03547807366399},
                {id:47,title:"Egekent Pazar Yeri", link :"/location/market/47",lat:38.50598530312322 , lng:27.064372720720957},
                {id:48,title:"Evka 5 Pazar Yeri", link :"/location/market/48",lat:38.52056598763158 , lng:27.0564796495573},
                {id:49,title:"File Market", link :"/location/market/49",lat:38.53162722604863 , lng:27.03140441333509},
                {id:50,title:"Organik Köy Pazarı", link :"/location/market/50",lat:38.46583834220466 , lng:27.08818508913564},
                {id:51,title:"Metro Market", link :"/location/market/51",lat:38.492508297930996 , lng:27.09331224156821},

            ],
            social : [
                {id:52,title:"Tematik Çocuk Köyü", link :"/location/social/52",lat:38.55010344802585 , lng:27.0490883782897},
                {id:53,title:"Kent Gözer Rekreasyon Alanı", link :"/location/social/53",lat:38.53770944173413 , lng:27.03798382072096},
                {id:54,title:"İlçe Halk Kütüphanesi", link :"/location/social/54",lat:38.55783916900979 , lng:27.04181747321668},
                {id:55,title:"Karagöl Tabiat Parkı", link :"/location/social/55",lat:38.562016076776274 , lng:27.21575583178792},
                {id:56,title:"Gölpark", link :"/location/social/56",lat:38.59648472037562 , lng:27.08418340105249},
                {id:57,title:"Örnekköy Milli Park", link :"/location/social/57",lat:38.50531391729539 , lng:27.112202920720957},
                {id:58,title:"İzmir Seyir Tepe", link :"/location/social/58",lat:38.489304356877085 , lng:27.125660257672674},
                {id:59,title:"Degaj Bölgesi", link :"/location/social/59",lat:38.44292509453587 , lng:26.973037717721347},
                {id:60,title:"Kuş Cenneti", link :"/location/social/60",lat:38.50386036961184 , lng:26.94184106906986},
                {id:61,title:"Homa Dalyanı", link :"/location/social/61",lat:38.5213177739827 , lng:26.84371712883633},
                {id:62,title:"Üçtepeler Leukai Antik Kenti", link :"/location/social/62",lat:38.546469421292635 , lng:26.85348572883633},
                {id:63,title:"Dünya Barış Anıtı", link :"/location/social/63",lat:38.49434654001769 , lng:27.146281057672674},
                {id:64,title:"Homeros Vadisi", link :"/location/social/64",lat:38.507616011927624 , lng:27.221688100000005},

            ],

            avm : [
                {id:65,title:"Mavibahçe AVM", link :"/location/avm/65",lat:38.473922323418705 , lng:27.074355443994207},
                {id:66,title:"Hilltown AVM", link :"/location/avm/66",lat:38.47961903545633 , lng:27.074677312793053},
                {id:67,title:"Efesus AVM", link :"/location/avm/67",lat:38.5198578008472 , lng:27.038301928836333},
                {id:68,title:"Westpark AVM", link :"/location/avm/68",lat:38.483479335665805 , lng:27.180232883084717},

            ],

            industry : [
                {id:70,title:"Ata Sanayi", link :"/location/industry/70",lat:38.499561930098736 , lng:27.047666437222734},
                {id:71,title:"Ege Sanayi", link :"/location/industry/71",lat:38.51692281099911 , lng:27.03510644450467},
                {id:72,title:"Çiğli Organize Sanayi", link :"/location/industry/72",lat:38.494211521061935 , lng:27.036895231723097},
                {id:73,title:"Atatürk Organize Sanayi", link :"/location/industry/73",lat:38.50009542690997 , lng:27.043061925346667},

            ]

        }


    },
    gallery : {

        exterior : [
            {caption:i18next.t("gallery.exterior") + ' 1', source: require("../img/gallery/exterior/i1.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 2', source: require("../img/gallery/exterior/i2.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 3', source: require("../img/gallery/exterior/i3.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 4', source: require("../img/gallery/exterior/i4.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 5', source: require("../img/gallery/exterior/i5.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 6', source: require("../img/gallery/exterior/i6.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 7', source: require("../img/gallery/exterior/i7.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 8', source: require("../img/gallery/exterior/i8.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 9', source: require("../img/gallery/exterior/i9.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 10', source: require("../img/gallery/exterior/i10.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 11', source: require("../img/gallery/exterior/i11.jpg")},
            {caption:i18next.t("gallery.exterior") + ' 12', source: require("../img/gallery/exterior/i12.jpg")},
        ],
        interior : [
            {caption:i18next.t("gallery.interior") + ' 1', source: require("../img/gallery/interior/i1.jpg")},
            {caption:i18next.t("gallery.interior") + ' 2', source: require("../img/gallery/interior/i2.jpg")},
            {caption:i18next.t("gallery.interior") + ' 3', source: require("../img/gallery/interior/i3.jpg")},
            {caption:i18next.t("gallery.interior") + ' 4', source: require("../img/gallery/interior/i4.jpg")},
            {caption:i18next.t("gallery.interior") + ' 5', source: require("../img/gallery/interior/i5.jpg")},
            {caption:i18next.t("gallery.interior") + ' 6', source: require("../img/gallery/interior/i6.jpg")},
            {caption:i18next.t("gallery.interior") + ' 7', source: require("../img/gallery/interior/i7.jpg")},
            {caption:i18next.t("gallery.interior") + ' 8', source: require("../img/gallery/interior/i8.jpg")},
            {caption:i18next.t("gallery.interior") + ' 9', source: require("../img/gallery/interior/i9.jpg")},
            {caption:i18next.t("gallery.interior") + ' 10', source: require("../img/gallery/interior/i10.jpg")},
            {caption:i18next.t("gallery.interior") + ' 11', source: require("../img/gallery/interior/i11.jpg")},
            {caption:i18next.t("gallery.interior") + ' 12', source: require("../img/gallery/interior/i12.jpg")},
            {caption:i18next.t("gallery.interior") + ' 13', source: require("../img/gallery/interior/i13.jpg")},
            {caption:i18next.t("gallery.interior") + ' 14', source: require("../img/gallery/interior/i14.jpg")},
            {caption:i18next.t("gallery.interior") + ' 15', source: require("../img/gallery/interior/i15.jpg")},
            {caption:i18next.t("gallery.interior") + ' 16', source: require("../img/gallery/interior/i16.jpg")},
            {caption:i18next.t("gallery.interior") + ' 17', source: require("../img/gallery/interior/i17.jpg")},
            {caption:i18next.t("gallery.interior") + ' 18', source: require("../img/gallery/interior/i18.jpg")},
            {caption:i18next.t("gallery.interior") + ' 19', source: require("../img/gallery/interior/i19.jpg")},
            {caption:i18next.t("gallery.interior") + ' 20', source: require("../img/gallery/interior/i20.jpg")},

        ],

        social : [
            {caption:i18next.t("gallery.interior"), source: require("../img/gallery/social/i1.jpg")},
            {caption:i18next.t("gallery.pool"), source: require("../img/gallery/social/i2.jpg")},
            {caption:i18next.t("gallery.jacuzzi"), source: require("../img/gallery/social/i3.jpg")},
            {caption:i18next.t("gallery.cafe"), source: require("../img/gallery/social/i4.jpg")},
            {caption:i18next.t("gallery.sauna"), source: require("../img/gallery/social/i5.jpg")},
            {caption:i18next.t("gallery.spa"), source: require("../img/gallery/social/i6.jpg")},
            {caption:i18next.t("gallery.gym"), source: require("../img/gallery/social/i7.jpg")},

        ]
    },
    brochure : [
        {image: require("../img/brochure/i1.webp")},
        {image: require("../img/brochure/i2.webp")},
        {image: require("../img/brochure/i3.webp")},
        {image: require("../img/brochure/i4.webp")},
        {image: require("../img/brochure/i5.webp")},
        {image: require("../img/brochure/i6.webp")},
        {image: require("../img/brochure/i7.webp")},
        {image: require("../img/brochure/i8.webp")},
        {image: require("../img/brochure/i9.webp")},
        {image: require("../img/brochure/i10.webp")},
        {image: require("../img/brochure/i11.webp")},
        {image: require("../img/brochure/i12.webp")},
        {image: require("../img/brochure/i13.webp")},
        {image: require("../img/brochure/i14.webp")},
        {image: require("../img/brochure/i15.webp")},
        {image: require("../img/brochure/i16.webp")},
        {image: require("../img/brochure/i17.webp")},
        {image: require("../img/brochure/i18.webp")},
        {image: require("../img/brochure/i19.webp")},
        {image: require("../img/brochure/i20.webp")},
        {image: require("../img/brochure/i21.webp")},
        {image: require("../img/brochure/i22.webp")},
        {image: require("../img/brochure/i23.webp")},
        {image: require("../img/brochure/i24.webp")},
        {image: require("../img/brochure/i25.webp")},
        {image: require("../img/brochure/i26.webp")},
        {image: require("../img/brochure/i27.webp")},
        {image: require("../img/brochure/i28.webp")},
        {image: require("../img/brochure/i29.webp")},
        {image: require("../img/brochure/i30.webp")},
        {image: require("../img/brochure/i31.webp")},
        {image: require("../img/brochure/i32.webp")},
        {image: require("../img/brochure/i33.webp")},
        {image: require("../img/brochure/i34.webp")},
        {image: require("../img/brochure/i35.webp")},
        {image: require("../img/brochure/i36.webp")},
        {image: require("../img/brochure/i37.webp")},
        {image: require("../img/brochure/i38.webp")},
        {image: require("../img/brochure/i39.webp")},
        {image: require("../img/brochure/i40.webp")},
        {image: require("../img/brochure/i41.webp")},
        {image: require("../img/brochure/i42.webp")},
        {image: require("../img/brochure/i43.webp")},
        {image: require("../img/brochure/i44.webp")},
        {image: require("../img/brochure/i45.webp")},
        {image: require("../img/brochure/i46.webp")},


    ],

    status : {
        available : 1,
        reserved : 2,
        sold : 3,
        blocked : 4,
        offer : 5,
        signed : 6,
        issue : 7,
    },
    getStatusTitle : function(id){
        let str = "";
        if(id === 1)  str = "Available";
        if(id === 2)  str = "Reserved";
        if(id === 3)  str = "Sold";
        if(id === 4)  str = "Blocked";
        if(id === 5)  str = "Offer / Negotiation";
        if(id === 6)  str = "Signed Contract";
        if(id === 7)  str = "Issue";

        return str;
    },
    role : {
        sales_rep : 1,
        sales_manager : 2,
        general_manager : 3,
        broker : 4
    },
    lead_status : {
        new : 1,
        active : 2,
        lost : 3,
        inactive : 4
    },
    showModal : function(className){
        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".modal."+className).classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");
    },
    closeModal : function(className){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".modal."+className).classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");
    }

};

export {
    appSettings,
    appConst,
};